import { GetStaticProps, GetStaticPropsContext, Page } from "next";
import { useContext, useEffect } from "react";
import { Language, TranslationScopes } from "@finbackoffice/enums";
import { getCookie } from "@finbackoffice/fe-core";
import { AuthContext, ConfigContext } from "@finbackoffice/site-core";
import { TOKEN_DETECTION_COOKIE } from "@finbackoffice/site-server-core";
import Seo from "components/base/seo/Seo";
import { NotFound } from "components/pages/404/NotFound";
import {
    clientBFFSingleton,
    getServerRuntimeConfig,
    withSsrConfigs,
    withSsrTranslations,
} from "services/ssr";
import { getCurrentLocale } from "utils/helpers";

export const getStaticProps: GetStaticProps = async (context: GetStaticPropsContext) => {
    const runtimeConfig = await getServerRuntimeConfig();
    const currentLocale = getCurrentLocale(runtimeConfig, context.locale) as Language;
    const client = clientBFFSingleton.getInstance();
    const translations = await withSsrTranslations(
        client,
        currentLocale,
        [TranslationScopes.NotFound],
        "mobile",
    );
    const configs = await withSsrConfigs(runtimeConfig, client);

    return {
        props: {
            configs,
            runtimeConfig,
            locale: getCurrentLocale(runtimeConfig, context.locale),
            translations,
        },
        // NOTE: this code disables static generation for this page, and therefore runtime variables are not cached
        revalidate: true,
    };
};

const NotFoundPage: Page = () => {
    const { loadSiteConfig } = useContext(ConfigContext);
    const { setToken } = useContext(AuthContext);

    useEffect(() => {
        const token = getCookie(TOKEN_DETECTION_COOKIE);
        if (token) {
            setToken(token);
        }
        loadSiteConfig();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Seo page={TranslationScopes.NotFound} />
            <NotFound statusCode={404} />
        </>
    );
};

export default NotFoundPage;
