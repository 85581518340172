import { FC } from "react";
import Link from "next/link";
import { TranslationScopes } from "@finbackoffice/enums";
import { SkinVersions } from "@finbackoffice/site-server-core";
import { formatAppNameText } from "@finbackoffice/fe-core";
import { useRuntimeConfig } from "@finbackoffice/site-core";
import Translate from "components/base/translate/Translate";
import Img from "components/base/img/Img";
import { Svg } from "components/base/svg/Svg";
import styles from "./not-found.module.sass";

type IProps = {
    statusCode: number;
};

export const NotFound: FC<IProps> = ({ statusCode }) => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    return (
        <section className={styles.notFoundPage}>
            <div className={styles.notFoundPageContent}>
                <div>
                    <div className={styles.leftBubble}>
                        <span>
                            <strong>{statusCode}</strong>
                            <Translate
                                tid="not_found_page_error"
                                namespace={TranslationScopes.NotFound}
                            />
                        </span>
                        <span>
                            <Translate
                                tid="not_found_page_head"
                                namespace={TranslationScopes.NotFound}
                            />
                        </span>
                        <p>
                            <Translate
                                tid="not_found_page_txt"
                                namespace={TranslationScopes.NotFound}
                            />
                        </p>
                    </div>
                    {COMMON_SITE_CONFIGS.skinVersion === SkinVersions.Xrpbet ? (
                        <Img
                            source={`${ASSETS_URL}/common/desktop/not-found/basketball.webp`}
                            alt="Not Found"
                            width={270}
                            height={211}
                        />
                    ) : (
                        <Svg
                            src={`/${formatAppNameText(COMMON_SITE_CONFIGS.appName)}/mobile/logo.svg`}
                            wrapper="span"
                            className={styles.notFoundLogo}
                        />
                    )}
                    <span className={styles.img} />
                </div>
                {statusCode !== 500 && (
                    <Link href="/" className={styles.goToHome}>
                        <Translate
                            tid="not_found_page_go_home"
                            namespace={TranslationScopes.NotFound}
                        />
                    </Link>
                )}
            </div>
        </section>
    );
};
